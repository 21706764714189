var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column is-narrow pl-0 pr-4 py-2 is-align-self-center"},[_c('p',{staticClass:"has-text-grey-light is-size-7"},[_vm._v(" "+_vm._s(_vm.$formatDateTime(_vm.data.event.at.toDate()))+" ")])]),_c('div',{staticClass:"column px-3 py-2",class:{
      'has-text-right': _vm.isSent,
      'has-text-left': _vm.isReceived,
      'has-text-centered': _vm.isService
    }},[_c('div',{staticClass:"is-inline-block",class:{
        'has-text-left notification px-3 py-2 is-primary is-light':
          _vm.isSent,
        'notification px-3 py-2': _vm.isReceived,
        'is-small-text is-italic': _vm.isService
      }},[_c('MessageTextRenderer',{attrs:{"data":_vm.data.text}}),(_vm.data.files && _vm.data.files.length > 0)?_c('div',_vm._l((_vm.data.files),function(doc,i){return _c('a',{key:doc.url,staticStyle:{"display":"block"},attrs:{"href":doc.url,"target":"_blank"}},[_vm._v(" 📝 "),_c('span',[_vm._v(" "+_vm._s(doc.name ? doc.name.substring(0, 20) : `Файл #${i + 1}`)+" ")]),(doc.size)?_c('em',{staticClass:"ml-2"},[_vm._v(" ("+_vm._s((doc.size / 1024 / 1024).toLocaleString('ru', { minimumFractionDigits: 0, maximumFractionDigits: 2 }))+" kb) ")]):_vm._e()])}),0):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }