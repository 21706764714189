import { formatDate as $formatDate, formatDateTime as $formatDateTime, formatDateTimeRange as $formatDateTimeRange, formatDuration as $formatDuration } from '@shveitsar/toolbox';
const formatters = {
    $formatDate,
    $formatDateTime,
    $formatDateTimeRange,
    $formatDuration
};
export const FormatPlugin = function (vue) {
    Object.assign(vue.prototype, formatters);
};
