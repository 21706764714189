import { RentStage } from '@shveitsar/rents-types';
export class RentEntity {
    id;
    data;
    static fromFirestoreValue(data) {
        return new RentEntity(data.id, data);
    }
    paymentStage;
    constructor(id, data) {
        this.id = id;
        this.data = data;
        this.paymentStage =
            data.stages.find((stage) => stage.key === RentStage.Payment) ?? null;
    }
    stageForKey(key) {
        return this.data.stages.find((stage) => stage.key === key);
    }
    get common() {
        return this.data.common;
    }
    get currentPhase() {
        return this.data.phase;
    }
    get currentStage() {
        return this.data.phase === 'pre'
            ? this.data.stages[this.data.stageIndex]
            : null;
    }
    get currentStageKey() {
        return this.currentStage?.key ?? null;
    }
    get rentDuration() {
        return (this.common.endAt.toMillis() - this.common.startAt.toMillis());
    }
    get cleaningDuration() {
        return (this.data.common.finishAt.toMillis() -
            this.data.common.endAt.toMillis());
    }
    get hasCleaning() {
        return this.cleaningDuration > 60 * 1000;
    }
    get phaseText() {
        return RentEntity.phaseLabel(this.data.phase);
    }
    get stageText() {
        const stageKey = this.currentStageKey;
        return stageKey && RentEntity.stageLabel(stageKey);
    }
    static phaseLabelMap = {
        pre: 'Подготовка',
        ready: 'Готов к аренде',
        renting: 'Проживание',
        cleaning: 'Уборка',
        finished: 'Завершен'
    };
    static phaseLabel(key) {
        return this.phaseLabelMap[key];
    }
    static stageLabelMap = {
        contract: 'Подписание договора',
        verification: 'Верификация',
        payment: 'Оплата'
    };
    static stageLabel(key) {
        return this.stageLabelMap[key];
    }
}
