import { Timestamp } from 'firebase/firestore';
export function compareDescPredicate(a, b) {
    if (a > b)
        return -1;
    if (a < b)
        return 1;
    return 0;
}
/** лейблы фоток паспорта */
export const passportPhotoLabelMap = {
    main: 'Главная',
    selfie: 'Селфи',
    visa: 'Прописка'
};
export function toFireTimestamp(date) {
    return Timestamp.fromDate(date);
}
