import { createDecorator } from 'vue-class-component';
export function ActionDecorator(loadingKey = 'isBusy') {
    return createDecorator(function (options, key) {
        const originalMethod = options.methods[key];
        options.methods[key] = async function (...args) {
            try {
                ;
                this[loadingKey] = true;
                return await originalMethod.apply(this, args);
            }
            catch (error) {
                this.$buefy.snackbar.open({
                    message: error.message,
                    type: 'is-danger'
                });
            }
            finally {
                ;
                this[loadingKey] = false;
            }
        };
    });
}
