var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"},[_c('div',{staticClass:"level-item"},[_c('p',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.num + 1)+". Подписание договора, "+_vm._s(_vm.value.urls.length)+" шт ")])]),_c('div',{staticClass:"level-item"},[_c('div',{staticClass:"buttons are-small"},[_c('b-button',{on:{"click":function($event){_vm.value.urls = [
                ..._vm.value.urls,
                {
                  url: 'https://flatsharing.biz',
                  name: 'Правила пользования объектом'
                }
              ]}}},[_vm._v(" + ")]),_c('b-button',{on:{"click":function($event){_vm.value.urls = _vm.value.urls.slice(
                0,
                _vm.value.urls.length - 1
              )}}},[_vm._v(" – ")])],1)])])]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-9"},_vm._l((_vm.value.urls),function(item,i){return _c('b-field',{key:i,attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":i.toString() === '0' ? 'URL договора' : null,"expanded":""}},[_c('b-input',{attrs:{"type":"text"},model:{value:(item.url),callback:function ($$v) {_vm.$set(item, "url", $$v)},expression:"item.url"}})],1),_c('b-field',{attrs:{"label":i.toString() === '0' ? 'Название договора' : null,"expanded":""}},[_c('b-input',{attrs:{"type":"text"},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})],1)],1)}),1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }