import { createFirebaseFunctionsCallableClient } from '@shveitsar/callable-frontend/firebase';
import { SERVICE_RENTS } from '@shveitsar/rents-types';
import { firebaseApp } from './firebase';
export const rentsApi = createFirebaseFunctionsCallableClient({
    functionName: `${SERVICE_RENTS}-api`,
    firebase: firebaseApp,
    emulator: process.env.VUE_APP_EMULATOR_FUNCTIONS
        ? (() => {
            const [host, port] = process.env.VUE_APP_EMULATOR_FUNCTIONS.split(':');
            return { host, port: +port };
        })()
        : undefined
});
