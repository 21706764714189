import { createCompareFn, formatDateTime, formatDateTimeRange, formatMoney, moneyDiff } from '@shveitsar/toolbox';
import { defineComponent } from 'vue';
export const RentListTable = defineComponent({
    props: {
        rents: { type: Array },
        tenantId: { type: String }
    },
    render() {
        const sortedRents = [...(this.$props.rents ?? [])].sort(createCompareFn((i) => i.common.endAt.toMillis(), -1));
        return (<b-table data={sortedRents} scopedSlots={{
                detail: ({ row }) => (<pre>{JSON.stringify(row?.data, null, 2)}</pre>)
            }} detailed>
        <b-table-column label="Период" scopedSlots={{
                default: ({ row }) => (<small>
                {formatDateTimeRange(row.common.startAt.toDate(), row.common.endAt.toDate())}
                ,
                <br />
                {row.hasCleaning
                        ? `конец уборки ${formatDateTime(row.common.finishAt.toDate())}`
                        : 'уборка не требуется'}
              </small>)
            }}/>

        <b-table-column label="Инфо" scopedSlots={{
                default: ({ row }) => (<small>
                <p>Тип: {row.common.tenant.type}</p>
                <p>Источник: {row.data.source}</p>
              </small>)
            }}/>

        <b-table-column label="Объект и агентство" scopedSlots={{
                default: ({ row }) => (<small>
                <p>
                  <a href={`https://stuff.flatapps.org/agencies/${row.data.agencyId}/apartments/${row.data.apartmentId}`} target="_blank">
                    {row.common.apartment.alias ||
                        row.common.apartment.address}
                  </a>
                </p>
                <p>
                  <a href={`https://stuff.flatapps.org/agencies/${row.data.agencyId}`} target="_blank">
                    {row.common.agency.name}
                  </a>
                </p>
              </small>)
            }}/>

        <b-table-column label="Гость" scopedSlots={{
                default: ({ row }) => (<small>
                {row.common.tenant.name && <p>{row.common.tenant.name}</p>}
                <p>
                  <a href={`https://profit.flatapps.org/passports?tenantId=${row.common.tenant.id}`} target="_blank">
                    {row.common.tenant.id}
                  </a>
                </p>
                <p>{row.common.tenant.phoneNumber}</p>
              </small>)
            }}/>

        <b-table-column label="Статус" scopedSlots={{
                default: ({ row }) => (<small>
                <p>Фаза: {row.phaseText}</p>
                {row.stageText && <p>Этап: {row.stageText}</p>}
              </small>)
            }}/>

        <b-table-column label="Оплата" scopedSlots={{
                default: ({ row }) => row.paymentStage ? (<small>
                  <p>
                    Комиссия:{' '}
                    {row.paymentStage.fee
                        ? formatMoney(row.paymentStage.fee)
                        : 'отсутствует'}
                  </p>
                  <p>
                    Аренда:{' '}
                    {row.paymentStage.total
                        ? formatMoney(row.paymentStage.fee
                            ? moneyDiff(row.paymentStage.total, row.paymentStage.fee)
                            : row.paymentStage.total)
                        : 'отсутствует'}
                  </p>
                  <p>
                    Залог:{' '}
                    {row.paymentStage.deposit
                        ? formatMoney(row.paymentStage.deposit)
                        : 'отсутствует'}
                  </p>
                </small>) : (<small>- отсутствует -</small>)
            }}/>

        <b-table-column label="Действия" scopedSlots={{
                default: ({ row }) => (<router-link to={this.$props.tenantId
                        ? {
                            name: 'tenant-rent-flow',
                            params: {
                                tenantId: row.common.tenant.id,
                                rentId: row.id
                            }
                        }
                        : {
                            name: 'rent-flow',
                            params: {
                                agencyId: row.data.agencyId,
                                rentId: row.id
                            }
                        }} title="Перейти к аренде">
                <b-icon icon="arrow-right"/>
              </router-link>)
            }}/>
      </b-table>);
    }
});
