import { rentsRef } from '@/services/firebase';
import { route$ } from '@/state';
import { doc, getDoc } from 'firebase/firestore';
import Vue, { defineComponent, h, onMounted } from 'vue';
import VueRouter from 'vue-router';
import AuthPage from '@/views/Auth/Container.vue';
import CreateRentPage from '@/views/CreateRent/Container.vue';
import RentFlowPage from '@/views/RentFlow/Container.vue';
import RentsListPage from '@/views/RentsList/Container.vue';
import CreateRentFormMain from '@/views/CreateRent/Form/Main.vue';
import CreateRentFormProperty from '@/views/CreateRent/Form/Property.vue';
import CreateRentFormStage from '@/views/CreateRent/Form/Stage.vue';
Vue.use(VueRouter);
const RouterView = Vue.extend({
    render(h) {
        return h('router-view');
    }
});
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    linkActiveClass: 'is-active',
    routes: [
        {
            path: '/auth',
            name: 'auth',
            component: AuthPage
        },
        {
            path: '/tenant/:tenantId',
            component: RouterView,
            children: [
                {
                    path: 'rents',
                    name: 'tenant-rents',
                    component: RentsListPage
                },
                {
                    path: 'rent/:rentId',
                    component: RouterView,
                    children: [
                        {
                            path: 'rent-flow',
                            name: 'tenant-rent-flow',
                            component: RentFlowPage
                        }
                    ]
                }
            ]
        },
        {
            path: '/home/:agencyId',
            component: RouterView,
            children: [
                {
                    path: 'create',
                    component: CreateRentPage,
                    props: true,
                    children: [
                        {
                            path: '',
                            name: 'create-main',
                            component: CreateRentFormMain
                        },
                        {
                            path: 'stages',
                            name: 'create-stages',
                            component: CreateRentFormStage
                        },
                        {
                            path: 'property',
                            name: 'create-property',
                            component: CreateRentFormProperty
                        }
                    ]
                },
                {
                    path: 'rents',
                    name: 'rents',
                    component: RentsListPage
                },
                {
                    path: 'rent/:rentId',
                    component: RouterView,
                    children: [
                        {
                            path: 'rent-flow',
                            name: 'rent-flow',
                            component: RentFlowPage
                        }
                    ]
                }
            ]
        },
        {
            path: '/rent/:rentId',
            props: true,
            component: defineComponent({
                props: {
                    rentId: { type: String, required: true }
                },
                setup(props) {
                    onMounted(async () => {
                        const rentSnap = await getDoc(doc(rentsRef, props.rentId));
                        if (rentSnap.exists()) {
                            router.replace({
                                name: 'tenant-rent-flow',
                                params: {
                                    rentId: rentSnap.id,
                                    tenantId: rentSnap.get('common.tenant.id')
                                }
                            });
                        }
                        else {
                            setTimeout(() => {
                                alert('Аренда не найдена');
                            }, 20);
                            router.replace({
                                name: 'auth'
                            });
                        }
                    });
                    return () => h('div', 'Ищем аренду...');
                }
            })
        },
        {
            path: '*',
            redirect: { name: 'auth' }
        }
    ]
});
route$.next(router.currentRoute);
router.afterEach((to) => {
    route$.next(to);
});
export default router;
