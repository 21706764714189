import { render, staticRenderFns } from "./PhoneAuth.vue?vue&type=template&id=52f4e6b3"
import script from "./PhoneAuth.vue?vue&type=script&lang=ts&setup=true"
export * from "./PhoneAuth.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports