import './assets/style.scss';
import Vue from 'vue';
import Buefy from 'buefy';
import VueRx from 'vue-rx';
import { FormatPlugin } from '@/services/format';
import router from './router';
import App from './App.vue';
Vue.config.devtools = true;
Vue.use(Buefy).use(VueRx).use(FormatPlugin);
new Vue({
    router,
    render: (h) => h(App)
}).$mount('#app');
// TODO Fix duplicate keys on dialog chats
// TODO !добавить страничку ШОШ
// TODO перенести остальные демки в gitlab pages
