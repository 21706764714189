var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('nav',{staticClass:"navbar is-primary"},[_c('div',{staticClass:"navbar-brand"},[_c('router-link',{staticClass:"navbar-item",attrs:{"to":{ name: 'auth' },"exact":""}},[_c('img',{attrs:{"src":require("../assets/logo.svg"),"width":"112"}})]),_c('a',{staticClass:"navbar-burger",class:_vm.isActive && 'is-active',on:{"click":_vm.toogle}},[_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{attrs:{"aria-hidden":"true"}})])],1),_c('div',{staticClass:"navbar-menu",class:_vm.isActive && 'is-active'},[_c('div',{staticClass:"navbar-start"},[(_vm.agencyId)?_c('router-link',{staticClass:"navbar-item",attrs:{"to":{
          name: 'create-main',
          params: { agencyId: _vm.agencyId }
        }}},[_vm._v(" Создать аренду ")]):_vm._e(),(_vm.agencyId)?_c('router-link',{staticClass:"navbar-item",attrs:{"to":{
          name: 'rents',
          params: { agencyId: _vm.agencyId }
        }}},[_vm._v(" Список аренд ")]):(_vm.tenantId)?_c('router-link',{staticClass:"navbar-item",attrs:{"to":{
          name: 'tenant-rents',
          params: { tenantId: _vm.tenantId }
        }}},[_vm._v(" Список аренд ")]):_vm._e(),(_vm.agencyId && _vm.rentId)?_c('router-link',{staticClass:"navbar-item",attrs:{"to":{
          name: 'rent-flow',
          params: { agencyId: _vm.agencyId, rentId: _vm.rentId }
        }}},[_vm._v(" Диалог ")]):(_vm.tenantId && _vm.rentId)?_c('router-link',{staticClass:"navbar-item",attrs:{"to":{
          name: 'tenant-rent-flow',
          params: { tenantId: _vm.tenantId, rentId: _vm.rentId }
        }}},[_vm._v(" Диалог ")]):_vm._e()],1),_c('div',{staticClass:"navbar-end"},[(_vm.auth)?_c('div',{staticClass:"navbar-item"},[_vm._v(" "+_vm._s(_vm.auth.phoneNumber)+" ")]):_vm._e(),(!_vm.auth)?_c('a',{staticClass:"navbar-item",on:{"click":_vm.signIn}},[_vm._v("Войти")]):_c('a',{staticClass:"navbar-item",on:{"click":_vm.signOut}},[_vm._v("Выход")])])]),_c('b-modal',{attrs:{"active":_vm.isShowLogin},on:{"update:active":function($event){_vm.isShowLogin=$event}}},[_c('PhoneAuth',{on:{"loggedIn":function($event){_vm.isShowLogin = false}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }