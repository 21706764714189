import { BUCKET_RECEIPTS, COLLECTION_RENTS, COLLECTION_RENTS_EVENTS } from '@shveitsar/rents-types';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { collection, CollectionReference, connectFirestoreEmulator, doc, getFirestore } from 'firebase/firestore';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
const firebaseOptions = JSON.parse(process.env.VUE_APP_FIREBASE_OPTIONS);
/**
 * Firebase App
 */
export const firebaseApp = initializeApp(firebaseOptions);
/**
 * Auth
 */
export const authApp = getAuth(firebaseApp);
/**
 * Storage
 */
export const receiptsStorageApp = getStorage(firebaseApp, `gs://${firebaseOptions.projectId}-${BUCKET_RECEIPTS}`);
if (process.env.VUE_APP_EMULATOR_STORAGE) {
    const [host, port] = process.env.VUE_APP_EMULATOR_STORAGE.split(':');
    connectStorageEmulator(receiptsStorageApp, host, +port);
}
/**
 * Firestore
 */
export const firestoreApp = getFirestore(firebaseApp);
if (process.env.VUE_APP_EMULATOR_FIRESTORE) {
    const [host, port] = process.env.VUE_APP_EMULATOR_FIRESTORE.split(':');
    connectFirestoreEmulator(firestoreApp, host, +port);
}
export const rentsRef = collection(firestoreApp, COLLECTION_RENTS);
export const rentEventsRef = (rentId) => collection(doc(rentsRef, rentId), COLLECTION_RENTS_EVENTS);
Reflect.set(globalThis, '__firebaseGetAuthToken', () => authApp.currentUser?.getIdToken());
