export function putValue(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}
export function restoreValue(key) {
    try {
        const serialized = localStorage.getItem(key);
        if (serialized === null) {
            return null;
        }
        else {
            return JSON.parse(serialized);
        }
    }
    catch (error) {
        return null;
    }
}
export class PersistedValue {
    key;
    prepare;
    constructor(key, prepare) {
        this.key = key;
        this.prepare = prepare;
    }
    put(value) {
        putValue(this.key, value);
    }
    restore() {
        const res = restoreValue(this.key);
        return this.prepare(res);
    }
}
export const agencyIdPersisted = new PersistedValue('agency-id', (res) => (res ? String(res) : ''));
export const tenantPhoneNumberPersisted = new PersistedValue('tenant-phone-number', (res) => (res ? String(res) : ''));
export const apartmentIdPersisted = new PersistedValue('apartment-id', (res) => (res ? String(res) : ''));
