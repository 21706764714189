import { render, staticRenderFns } from "./RouterTabs.vue?vue&type=template&id=06fc2d6e"
import script from "./RouterTabs.vue?vue&type=script&lang=ts"
export * from "./RouterTabs.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports