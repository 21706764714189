import { resolveGaeOrigin } from '@shveitsar/callable-common';
import { PassportsClient } from '@shveitsar/passports-client';
import { rentsApi } from './rents';
export const passportsClient = new PassportsClient(resolveGaeOrigin('passports', process.env.VUE_APP_GCP_PROJECT));
export async function uploadPassportPhotos(tenantId, files, rentId) {
    await passportsClient.uploadPhotos({
        uuid: tenantId,
        recognize: false,
        files: files.map(({ key, file }) => ({
            type: key,
            filename: file.name,
            buffer: file
        }))
    });
    if (rentId) {
        await rentsApi.verification.tenantPassportUpload(rentId);
    }
}
export async function getPhotoUrls(tenantId) {
    const result = await passportsClient.getPhotosURL(tenantId);
    if ('error' in result) {
        console.error(result);
        return {};
    }
    else {
        return result;
    }
}
