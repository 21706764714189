import { PlatformProvider, providerAdditionals, ProviderLocale, providerServiceManager, providerServiceTenants, providerStageContract, providerStagePayment, providerStageVerification } from '@shveitsar/messages-core';
import { combineLatest } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { currentRent$, currentRentEvents$ } from '.';
const frontendStdProvider = new PlatformProvider([
    providerServiceManager,
    providerServiceTenants,
    providerAdditionals,
    providerStageContract,
    providerStageVerification,
    providerStagePayment
], ProviderLocale.Ru);
export const currentRentMessages$ = combineLatest([
    currentRent$,
    currentRentEvents$
]).pipe(map(([rent, events]) => {
    if (!rent) {
        return [];
    }
    const messagesIter = frontendStdProvider.handleAllEvents(events, {
        id: rent.id,
        ...rent.data
    });
    return Array.from(messagesIter);
}), shareReplay(1));
